<template>
  <div class="invited" v-cloak>
    <div class="usercenter">
      <div class="userbox">
        <div class="userheader">
          <img
            :src="headerImg || require('../../assets/logo.png')"
            alt=""
            srcset=""
          />
        </div>
        <div class="userinfo">
          <div class="name">{{ userinfo.realname }}</div>
          <div>{{ userinfo.phone }}</div>
        </div>
      </div>
    </div>

    <div class="info-box">
      <div class="">
        <van-cell-group inset>
          <van-cell title="账号" :value="userinfo.username" />
          <van-cell
            title="手机号码"
            is-link
            value-class="c-4D4D4D"
            :value="userinfo.phone"
            @click="linkUrl('/setphone', userinfo.phone, userinfo.id)"
          />
          <!-- <van-cell title="邮箱号码" is-link  itle-class='w40' value-class='w55' :value="userinfo.email" @click="linkUrl('/setemail')"/> -->
          <van-cell
            title="邮箱号码"
            itle-class="w40"
            value-class="w55"
            :value="userinfo.email"
          />
          <van-cell
            title="绑定微信号"
            is-link
            value-class="c-4D4D4D"
            value="去解绑"
            @click="linkUrl('/setwx')"
          />
          <van-cell
            title="密码管理"
            is-link
            value-class="c-4D4D4D"
            value="去重置"
            @click="linkUrl('/setpassword', userinfo.username)"
          />
        </van-cell-group>
      </div>

      <div class="mt10">
        <van-cell-group inset>
          <van-cell
            title="所属部门"
            itle-class="w40"
            value-class="w55"
            :value="userinfo.departName"
          />
          <!-- <van-cell title="所属部门" value="后勤部" /> -->
          <van-cell title="员工编号" :value="userinfo.workNo" />
        </van-cell-group>
      </div>
    </div>
    <TabberItem :active="active"></TabberItem>
  </div>
</template>

<script>
import { Toast, Cell, CellGroup } from "vant";
import Cookies from "js-cookie";
import config from "@/config";
import TabberItem from "@/components/TabberItem";
import { initMixin } from "@/mixins/initMixin";
export default {
  name: "index",
  mixins: [initMixin],
  data() {
    return {
      active: 4,
      userinfo: {},
      headerImg: "",
    };
  },
  methods: {
    // 获取用户信息
    async getDate() {
      Toast.loading();
      var openId = Cookies.get("openid");
      let params = {
        openId: openId,
      };
      await this.$post(`/h5/weChat/getSysUser`, params).then((res) => {
        if (res.code == "0") {
          Toast.clear();
          this.userinfo = res.result;

          this.headerImg = config.imgUrl + "/" + res.result.avatar;
        }
      });
    },
    linkUrl(url, val, id) {
      this.$router.push({ path: url, query: { urlVal: val, id: id } });
    },
  },
  created() {
    this.initFn();
  },
  mounted() {},
  components: {
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    TabberItem,
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.invited {
  background: #f4f4f4;
  min-height: 100vh;
}
.usercenter {
  background: linear-gradient(0deg, #5297ff 0%, #526fff 100%);
  box-shadow: 0px 11px 16px 0px rgba(0, 0, 0, 0.02);
  padding: 25px 0 0 13px;
  .userbox {
    overflow: hidden;
    padding-bottom: 172px;
    .userheader {
      float: left;
      width: 60px;
      height: 60px;
      background: #f5f5f5;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .userinfo {
      float: left;
      color: #fff;
      .name {
        margin: 6px 0;
      }
    }
  }
}
.info-box {
  margin-top: -150px;
  .van-cell {
    padding: 15px;
  }
}
.c-4D4D4D {
  color: #4d4d4d;
}
.w40 {
  flex: 0 0 40%;
}
.w55 {
  flex: 0 0 55%;
}
</style>